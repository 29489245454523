<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 gray-bg profile-assigned-resources"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_profile-help-online-assigned'
        }"
      >
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "profile",
              "assigned-resources",
              "assigned-resources"
            )
          }}
        </div>
      </top-header-menu-wrapper>
    </template>

    <section>
      <ul class="clebex-item-section pill icon-app1">
        <li
          v-for="resourceAssigned in resourcesAssigned"
          :key="resourceAssigned.id"
          class="clebex-item-section-item"
        >
          <router-link
            :to="{
              name: 'r_profile-edit-assigned-resources',
              params: {
                assignedResourceId: resourceAssigned.id
              }
            }"
          >
            <div class="clebex-item-content-wrapper">
              <span class="label">
                <span class="text">
                  <span class="highlight">{{
                    resourceAssigned.resource.name
                  }}</span>
                  <span
                    v-if="
                      resourceAssigned &&
                        resourceAssigned.resource &&
                        resourceAssigned.resource.full_path
                    "
                    >,
                    {{ resourceAssigned.resource.full_path.join(", ") }}</span
                  >
                </span>

                <span
                  class="text"
                  v-if="
                    resourceAssigned &&
                      resourceAssigned.resource_assignment_type_id === 2
                  "
                >
                  <span class="highlight">
                    {{ displayLabelName("manage-users", "edit-user", "mode") }}:
                  </span>
                  <span>
                    {{
                      displayLabelName(
                        "manage-users",
                        "assign-resources",
                        "always-booked"
                      )
                    }}
                  </span>
                </span>
              </span>
              <span class="follow-up-icons">
                <span class="follow-up-icon-item">
                  <div class="svg-icon">
                    <svg
                      width="12"
                      height="12"
                      style="width: 12px; height: 12px;"
                    >
                      <use xlink:href="#cx-app1-arrow-right-12x12"></use>
                    </svg>
                  </div>
                </span>
              </span>
            </div>
          </router-link>
        </li>
      </ul>
    </section>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { getUserId } from "@/services/http-service";
import { mapActions, mapGetters } from "vuex";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "ProfileAssignedResources",
  mixins: [helpOnlineMixin],
  data() {
    return {
      id: getUserId(),
      helpSlug: "profile-assigned"
    };
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  created() {
    this.$store.commit("user/setUserResourcesAssigned", null, {
      root: true
    });

    this.getUserResourcesAssigned({
      id: this.id,
      params: {
        includes: ["resource_full_path"]
      }
    });
  },
  computed: {
    ...mapGetters("user", ["resourcesAssigned"])
  },
  methods: {
    ...mapActions("user", ["getUserResourcesAssigned"])
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
